.featured-header {
  background-position: center center;
  background-size: cover;
  height: 240px;
  margin: 0 auto 32px auto;
  position: relative;

  @include bp(md) {
    height: 560px;
    margin: 0 auto 50px auto;
  }

  &--large {
    height: 400px;
    margin: 0 auto;
    @include bp(md) {
      height: 700px;
    }
  }

  @at-root #{&}__title {
    @include font-gibson(600, normal);
    display: inline-block;
    margin: 0;
    padding: 0;

    @at-root #{&}--inside {
      bottom: 90px;
      position: absolute;
      text-shadow: 0 0 20px rgba(#000000, 0.5);

      @include bp(sm) {
        bottom: 100px;
      }

      @include bp(md) {
        bottom: 130px;
        max-width: 900px;
      }
    }


    &--outside {
      bottom: -30px;
      position: absolute;
    }


    &--white {
      color: #ffffff;
      font-size: 25px;
      line-height: 38px;

      @include bp(sm) {
        font-size: 41px;
        line-height: 48px;
      }

      @include bp(md) {
        font-size: 56px;
        line-height: 60px;
        max-width: 900px;
      }
    }


    &--purple {
      background: #F4EEFA;
      color: $brand-purple-muted;
      font-size: 30px;
      line-height: 43px;
      padding: 8px 18px;

      @include bp(sm) {
        font-size: 41px;
        line-height: 48px;
      }

      @include bp(md) {
        font-size: 50px;
        line-height: 60px;
        max-width: 900px;
        padding: 30px 20px 20px 20px;
      }
    }

  }

}

.featured-header.main-page {
  .featured-header--inner {
    @include bp(lg) {
      bottom: 50px;
    }
  }
}
