.alert-banner {
    background-color: #F3EBF9;
    color: #5E2984;
    width: 100%;
    max-height: 68px;
    max-width: 591px;
    display: flex;
    margin-bottom: 23px;

    @media (max-width: 591px) {
        max-height: none;
    }

    p {
        padding-left: 42px;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        font-size: 16px;
        line-height: 24px;
        background: url('/img/alert-icon.svg');
        background-repeat: no-repeat;
        background-position: 4px 3px;
    }

    &__large {
        height: 80px;
        max-height: none;
        max-width: none;
        margin-bottom: 52px;
        @media (max-width: 591px) {
            max-height: none;
            height: auto;
        }

        .alert-banner__inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        P {
            font-size: 20px;
            line-height: 36px;
            margin-top: 8px;
            margin-right: 25px;
            margin-left: 25px;
            background-position: 1px 7px;
            background-size: 29px;
        }
    }

    &__limit-width {
        max-width: 1340px;
    }
}