.quick-links {

  background: #F1F1F1;
  margin: -70px 0 30px 0;
  padding-bottom: 30px;

  @at-root #{&}__hero {
    background: #ffffff;
    @include box-shadow(0px 0px 20px -5px rgba(0, 0, 0, 0.5));
    height: 124px;
    margin-bottom: 12px;

    @include bp(sm) {
      height: 190px;
      margin-bottom: 17px;
    }

    @include bp(lg) {
      height: 150px;
      margin-bottom: 30px;
    }

    a {
      display: block;
      height: 100%;
    }

    img {
      display: none;
      float: right;
      height: 100%;
      width: auto;

      @include bp(xs) {
        display: block;
      }

    }


  }


  @at-root #{&}__title {
    display: inline-block;
    color: $brand-purple;
    font-size: 23px;
    line-height: 28px;
    margin: 22px 0 10px 22px;

    @include bp(sm) {
      font-size: 32px;
      line-height: 38px;
      margin: 24px 0 10px 32px;
    }

    @include bp(lg) {
      font-size: 22px;
      line-height: 34px;
      margin: 24px 0 10px 18px;
    }

    @include bp(xl) {
      font-size: 28px;
      line-height: 34px;
      margin: 24px 0 0 24px;
    }

  }


  @at-root #{&}__questions {
    @include list-unstyled();
    color: #828282;
    font-size: 16px;

    li {
      margin-bottom: 26px;
    }

    @at-root #{&}__intro {
      color: #828282;
      font-size: 15px;
      line-height: 21px;

      @include bp(sm) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    a {
      color: #70309F;
      display: inline-block;
      @include font-gibson(600);
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 8px;
      &:hover {
        text-decoration: none;
      }
      span {
        border-bottom: 1px solid #8364AA;
      }
    }

  }


}

