.main-footer {

  padding-top: 48px;

  @at-root #{&}__cta {
    background: $brand-purple;
    height: 185px;
    margin-bottom: 47px;
    position: relative;

    @include bp(sm) {
      height: 210px;
    }

    @include bp(md) {
      height: 150px;
    }

    p {
      color: #fff;
      font-size: 21px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      @include transform(translateY(-50%));
      text-align: center;

      @include bp(sm) {
        font-size: 24px;
      }

      @include bp(md) {
        font-size: 27px;
      }


      span {
        display: block;
        margin: 0 30px 20px 30px;

        @include bp(md) {
          display: inline-block;
          margin: 0 30px 0 0;
        }

      }



    }

  }



  @at-root #{&}__colophon {

    @include bp(md) {
      margin-bottom: 44px;
    }


    @at-root #{&}__logo {
      margin-bottom: 20px;

      @include bp(sm) {
        margin-bottom: 28px;
      }
    }


    .sitemap-nav {

      margin-bottom: 40px;

      @include bp(sm) {
        max-width: 500px;
      }

      @include bp(md) {
        max-width: 100%;
      }

      ul {
        @include list-unstyled();
        @include column-count(3);

        @include bp(md) {
          @include column-count(3);
        }

      }


      li {
        @include font-gibson(600);
        font-size: 16px;
        line-height: 33px;

        @include bp(sm) {
          font-size: 18px;
          line-height: 36px;
        }
      }


    }


    .contact-us {
      color: $brand-purple-light;
      margin-bottom: 42px;


      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px;


        a {
          color: $brand-purple-light;
          @include font-gibson(400);
          &:hover {
            color: $brand-purple-dark;
          }
        }


      }


    }

  }


  @at-root #{&}__misc {
    @include clearfix();
    border-top: 1px solid #E6E1F0;
    color: $brand-purple-light;
    font-size: 15px;
    padding: 20px 0 50px 0;

    @include bp(sm) {
      padding: 20px 0 20px 0;
    }

    @include bp(md) {
      padding: 12px 0 20px 0;
    }

    nav {
      @include bp(md) {
        float: left;
      }
    }


    ul {
      @include list-unstyled();


      li {
        font-size: 13px;
        line-height: 16px;

        @include bp(sm) {
          display: inline-block;
          font-size: 15px;
          margin-right: 2em;
        }


        a {
          color: $brand-purple-light;
          @include font-gibson(400);
          &:hover {
            color: $brand-purple-dark;
          }
        }


      }
    }
  }


  @at-root #{&}__credit {

    font-size: 13px;
    line-height: 16px;

    @include bp(sm) {
      display: inline-block;
      font-size: 15px;
      margin-right: 2em;
    }


    a {
      color: $brand-purple-light;
      @include font-gibson(400);
      &:hover {
        color: $brand-purple-dark;
      }
    }

    @include bp(md) {
      float: right;
    }

  }

}

.footer__social-link {
  img {
    max-width: 90px;
  }
}