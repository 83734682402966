.aside {
  border-top: 15px solid #F7F5FA;
  margin-bottom: 40px;
  padding-top: 24px;

  @at-root #{&}__menu {
    @include list-unstyled();
  }

  @at-root #{&}__title {
    color: #70309F;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  @at-root #{&}__item {
    @include list-unstyled();
    @include clearfix();
    font-size: 17px;
    @include font-gibson(400);
    margin-bottom: 14px;

    a {
      color: #70309F;
      display: block;
    }

    &--small {
      font-size: 18px;
    }

    &--current {
      a {
        color: #B2A2C6;
        text-decoration: underline;
      }
    }

    &--has-image {
      img {
        float: left;
        margin-right: 18px;
      }
      .aside__title {
        color: $brand-purple-muted;
        font-size: 18px;
        line-height: 23px;
      }
    }

  }

}
