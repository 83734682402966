.content-builder {

    @at-root #{&}__largeImage {
        margin-bottom: 30px;

        @include bp(lg) {
            margin-bottom: 66px;
        }

        img {
            @include img-responsive();
            margin-bottom: 19px;
        }
        figcaption {
            @include font-gibson(400);
            font-size: 17px;
            line-height: 26px;
        }

    }


    ul {
        list-style: none;
        margin-bottom: 15px;
        padding: 0 0 0 20px;

        @include bp(sm) {
            margin-bottom: 30px;
            padding: 0 0 0 20px;
        }

        @include bp(md) {
            margin-bottom: 32px;
        }

        li {
            margin: 0 0 15px 0;
            padding: 0;

            &:before {
                content: '';
                display: inline-block;
                @include icon-sprite-tick-bullet();
                margin-right: .5em;
                margin-left: #{(-$sprite-tick-bullet-width - .5em)};

            }
        }
    }


    @at-root #{&}__accordion {

        @at-root #{&}__title {
            background: #F7F5FA;
            box-sizing: border-box;
            color: $brand-purple-muted;
            cursor: pointer;
            @include font-gibson(600);
            font-size: 18px;
            line-height: 26px;
            padding: 15px 30px 15px 15px;
            position: relative;

            @include bp(sm) {
                font-size: 21px;
                line-height: 30px;
                padding: 26px 64px 18px 33px;
            }

            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                right: 20px;
                top: 45%;
                @include transition(all .2s ease-in-out);
            }

        }


        @at-root #{&}__content {
            border-top: 1px solid #CECECE;
            box-sizing: border-box;
            padding: 15px 30px 15px 15px;

            @include bp(sm) {
                font-size: 21px;
                line-height: 30px;
                padding: 37px 42px 18px 33px;
            }
        }


        &--open {
            border: 1px solid #CECECE;
            .content-builder__accordion__content {
                display: block;
            }

            .content-builder__accordion__title {
                &:after {
                    @include icon-sprite-accordion-chevron();
                    @include transform(rotate(180deg));
                }
            }
        }

        &--closed {
            border: 1px solid #CECECE;
            border-bottom: none;

            .content-builder__accordion__content {
                display: none;
            }

            .content-builder__accordion__title {
                &:after {
                    @include icon-sprite-accordion-chevron();
                }
            }
        }


    }

    & .content-builder__accordion--open {
        border-bottom: none;

        &:last-child {
            border-bottom: 1px solid #CECECE;
        }
    }

    & .content-builder__accordion--closed {
        &:last-child {
            border-bottom: 1px solid #CECECE;
        }
    }



    table {
        border: 1px solid #CECECE;
        margin-bottom: 40px;
        width: 100%;
        thead {
            background: $brand-purple-light;
            td, th {
                color: #ffffff;
                @include font-gibson(400);
                font-size: 15px;
                line-height: 20px;
                padding: 8px;
                text-align: left;
            }
        }

        tbody {
            th {
                color: #595959;
                @include font-gibson(600);
                font-size: 15px;
                line-height: 20px;
                padding: 8px;
                text-align: left;
            }
            td {
                color: #595959;
                @include font-gibson(400);
                font-size: 15px;
                line-height: 20px;
                padding: 8px;
                //text-align: left;
            }
            tr {
                border-bottom: 1px solid #CECECE;
            }
        }
        &.grid {
            width: auto;
            td {
                border: 1px solid #CECECE;
                font-size: 16px;
                line-height: 19px;
                padding: 25px 16px;
                text-align: center;
                width: 33%;
            }
        }
    }
    .table--funds {
        border: 0;
        line-height: 1.3;
        tr {
            border-color: #FFFFFF;
        }
        p {
            margin-bottom: 0.4em;
            font-size: 15px;
            line-height: 1.5;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .td-purple {
        color: #FFFFFF;
        background-color: $brand-purple;
    }
    .td-purple-lighter {
        color: #000000;
        background-color: $brand-purple-lighter;
    }
}
