.pagination-container {
  display: block;

  &--center {
    text-align: center;
  }
}

.pagination {
  display: block;
  list-style: none;
  padding: 0;

  @at-root #{&}__item {

    border: 1px solid $brand-purple-lighter;
    @include font-gibson(600);
    display: inline-block;
    margin: 0 8px 8px 0;

  }

  @at-root #{&}__page {
    color: #70309F;
    display: block;
    text-decoration: none;
    font-size: 17px;
    height: 44px;
    line-height: 44px;
    min-width: 44px;
    text-align: center;

    &:hover {
      text-decoration: none;
    }


    &--first, &--last {
      min-width: 88px;
    }

    &--disabled, &--active {
      background: #F3EEFA;
      color: #70309F;
    }

    &--prev {
      &:before {
        content: '';
        display: inline-block;
        @include icon-sprite-purple-chevron();
        @include transform(rotate(180deg));
      }
    }

    &--next {
      &:before {
        content: '';
        display: inline-block;
        @include icon-sprite-purple-chevron();
      }
    }


  }


}
