.questions {
  @include clearfix();
  @include list_unstyled();

  @at-root #{&}__item {
    @include list_unstyled();
    margin-bottom: 20px;

    @include bp(sm) {
      float: left;
      margin-bottom: 30px;
      margin-right: 7%;
      width: 43%;

      &:nth-child(odd){
        clear: both;
      }
    }
  }


  @at-root #{&}__question {
    @include font-gibson(600);
    color: #70309F;
    display: inline-block;
    font-size: 21px;
    line-height: 32px;
    margin-bottom: 10px;

    &:hover {
      text-decoration: none;
    }

    span {
      border-bottom: 1px solid #8364AA;
    }

    @include bp(sm) {
      font-size: 25px;
    }


  }

  @at-root #{&}__answer {
    color: #828282;
    font-size: 15px;
    line-height: 21px;

    @include bp(sm) {
      font-size: 16px;
      line-height: 24px;
    }

  }

}


