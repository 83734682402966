.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    color: #FFFFFF;
    background-color: rgba(142,96,202,0.8);
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 0.1s;
    &.active {
        visibility: visible;
        opacity: 1;
    }
}
.modal__heading {
    display: block;
    margin-bottom: 1.4em;
    font-size: 22px;
    text-align: center;
    @include font-gibson(300);
}
.login-block {
    display: flex;
    flex-direction: column;
    max-width: 788px;
    text-align: center;
    background-color: #FFFFFF;
    > div {
        padding: 30px;
    }
    > div:first-child {
        border-bottom: 1px solid rgba(151,151,151,0.15); 
    }
    strong {
        display: block;
        margin-bottom: 0.8em;
        font-size: 18px;
        color: #4F4F4F;
        @include font-gibson(600);
    }
}
.modal__close {
    display: block;
    position: absolute;
    right: 30px;
    top: 30px;
    width: 20px;
    height: 19px;
    background: url(/img/close-white.svg) no-repeat center center;
    background-size: 20px 19px;
    cursor: pointer;
}
@media only screen and (min-width: 768px) {
    .modal__close {
        right: 54px;
        top: 54px;
    }
    .modal__heading {
        font-size: 34px;
    }
    .login-block {
        flex-direction: row;
        > div {
            position: relative;
            padding: 52px 77px;
        }
        > div:first-child {
            border-bottom: 0; 
            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                height: 50%;
                transform: translateY(-50%);
                background-color: rgba(151,151,151,0.15);
            }
        }
        strong {
            font-size: 24px;
        }
        .btn {
            min-width: 222px;
        }
    }
}