.cushon {
    clip-path: ellipse(66% 55% at 50% 45%);

    @media (max-width: 1123px) {
        clip-path: ellipse(122% 55% at 50% 45%)
    }
    
    .container {
        max-width: 927px;
        display: flex;
    }

    &__logo {
        height: 200px;

        img {
            margin-top: 57px;
        }
    }
    
    &__inner {
        background-color: #E52D90;
        width: 100%;
        height: 620px;
        background-image: url('/img/cushon-graphic.png');
        background-repeat: no-repeat;
        background-position: 83.6% 100px;

        @media (max-width: 1123px) {
            background-position: 95.6% 262px;
        }
        @media (max-width: 709px) {
            background-position: 95.6% 314px;
        }
        @media (max-width: 493px) {
            background-position: 161% 374px;
        }
        @media (max-width: 375px) {
            background-position: 100px 422px;
        }
    }

    &__text {
        max-width: 686px;

        h1 {
            font-size: 36px;
            line-height: 46px;
            color: #FFFFFF;
            margin-top: 97px;
            margin-bottom: 22px;
        }

        p {
            font-size: 18px;
            line-height: 28px;
            color: #FFFFFF;
        }

        a {
            max-width: 167px;
            font-size: 13px;
            line-height: 12px;
        }
    }
}