.banner {
    display: flex;
    justify-content: center;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    height: auto;

    @media (max-width: 1200px) {
        height: 117px;
    }

    @media (max-width: 1200px) {
        height: 140px;
    }

    @media (max-width: 652px) {
        height: 100%;
        max-height: none;
    }

    &__inner {
        display: flex;
        max-height: 80px;
        padding: 0;
        @media (max-width: 652px) {
            flex-direction: column;
            margin: 15px;
            height: 100%;
            max-height: none;
        }
    }

    &__logo {
        align-self: center;
        // content: url('/img/cushon-logo.png');
        max-height: 50px;
        margin: 10px 0 20px 18px;
        padding-right: 30px;
    }

    &__separator {
        align-self: center;
        height: 41px;
        padding-right: 1px;
        background-color: #D9D9D9;

        @media (max-width: 652px) {
            display: none;
        }
    }

    &__text {
        align-self: center;
        margin-left: 30px;
        max-height: 43px;
        width: 100%;

        h2 {
            font-size: 20px;
            line-height: 20px;
            color: #E52D90;
            margin-bottom: 5px;
        }

        p {
            margin: 0;
            font-weight: 300;
            font-size: 16px;
            line-height: 16px;
            color: #2F2F2F;
        }

        @media (max-width: 652px) {
            max-height: none;
            width: auto;
            margin: 15px;
        }
    }

    &__cta {
        align-self: center;
        min-width: 165px;
        margin-right: 9px;
    }
}