.form-group {

  @include bp(sm) {
    margin-bottom: 30px;
  }

}

label {
  @include font-gibson(600);
  color: $brand-purple-muted;
  font-size: 18px;
  margin-bottom: 8px;

  & > a {
    color: #70309F;
    font-size: 15px;
    text-decoration: underline;
  }


  &.btn {
    border-width: 1px;
    border-radius: 2px;
    margin: 1px;

    &.active {
      margin: 0;
    }

    input {
      display: none;
    }
  }
}


.form-control {
  font-size: 17px;

  @include bp(md) {
    font-size: 18px;
  }

  &:focus {
    @include box-shadow(none);
    outline: 0 none;
  }

  &--inline {
    width: auto;
  }
}



select {
  &.form-control {
    background: #F7F5FA url(/img/select-chevron.svg) no-repeat right 15px center;
    border-radius: 2px;
    margin-right: 20px;
    @include appearance(none);
  }
}

.help-block {
  font-size: 18px;
  color: #858585;
  line-height: 25px;
}

.btn {
  &:focus {
    outline: none;
  }
}


#form-contact-us {
  margin-bottom: 20px;
  @include bp(sm) {
    margin-bottom: 40px;
  }
}