.featured-resources {
  @include clearfix();
}

.resource {
  @include clearfix();
  display: block;
  font-size: $font-size-base;
  margin-bottom: 20px;
  min-height: $sprite-pdf-icon-height;

  & > a {
    display: block;
    &:hover {
      text-decoration: none;
    }
  }

  @at-root #{&}__icon {
    display: block;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &--pdf {
 
      &:before {
        @include icon-sprite-pdf-icon();
      }

      &:hover:before {
        @include icon-sprite-pdf-hover-icon();
      }
    }

  }

  @at-root #{&}__copy {
    padding-left: 60px;
  }

  @at-root #{&}__category {
    color: #818181;
    display: block;
    @include font-gibson(400);
    font-size: 15px;
    line-height: 18px;
  }

  @at-root #{&}__title {
    color: #4F4F4F;
    display: block;
    @include font-gibson(600);
    font-size: 20px;
    line-height: 24px;

    &--purple {
      color: $brand-purple-muted;
    }

    &--small {
      color: $brand-purple;
      display: inline-block;
      font-size: 15px;
      margin-left: 1em;
      text-decoration: underline;

    }
  }

  @at-root #{&}__description {
    color: #858585;
    display: block;
    @include font-gibson(400);
    font-size: 18px;
    line-height: 25px;
  }

}

.resource {

  @at-root #{&}__icon {
    display: block;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &--doc {
      i {
        @include font-gibson(600);
        font-size: 15px;
        color: white;
        position: relative;
        padding-top: 17px;
        text-align: center;
        width: 41px;
        display:  inline-block;
        float: left;
        z-index: 99;
      }

      &:before {
        @include icon-sprite-resource-icon();
      }

      &:hover:before {
        @include icon-sprite-resource-hover-icon();
      }
    }

  }

}

.resource-list {

  @include clearfix();
  @include list-unstyled();

  li {
    @include clearfix();
    @include list-unstyled();
    display: block;
    margin: 0 20px 10px 0;
    padding: 10px 0;

    @include bp(sm) {
      margin: 0 50px 20px 0;
      padding: 10px 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &--inline {
    li {
      display: inline-block;
    }
  }

  &--has-separator {
    li {
      border-bottom: 1px solid #E1E1E1;
      &:last-child {
        border: none;
      }
    }
  }

}


.popular-resources {

  li {
    @include bp(sm) {
      border: none;
      display: inline-block;
      float: left;
      width: 50%;
      &:nth-child(2n+1){
        @include clearfix();
      }
    }

    @include bp(md) {
      border-bottom: 1px solid #E1E1E1;
      display: block;
      float: none;
      width: 100%;
    }

  }

}


.resources-panel {
  img {
    width: 100%;
  }
  @at-root #{&}__title {
    color: $brand-purple;
    font-size: 28px;
    margin: 20px 22px 15px 22px;

    @include bp(sm) {
      font-size: 18px;
      margin: 16px 10px 15px 15px;
    }

    @include bp(md) {
      font-size: 23px;
      margin: 20px 22px 15px 22px;
    }

    @include bp(lg) {
      font-size: 28px;
      margin: 20px 22px 15px 22px;
    }
  }
}
