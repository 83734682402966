
@mixin bp($point) {
    @if $point == xl {
        @media screen and (min-width: $screen-xl) { @content; }
    }
    @elseif $point == lg {
        @media screen and (min-width: $screen-lg) { @content; }
    }
    @else if $point == md {
        @media screen and (min-width: $screen-md) { @content; }
    }
    @else if $point == sm {
        @media screen and (min-width: $screen-sm) { @content; }
    }
    @else if $point == xs {
        @media screen and (min-width: $screen-xs) { @content; }
    }
}

@mixin background-overlay() {
    position: relative;

    &:before {
        background: rgba(#000000, .18);
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

@mixin purple-right-arrow() {
    display: inline-block;
    &:after {
        content: '';
        @include icon-sprite-purple-right-arrow();
        display: inline-block;
        margin-left: 10px;
    }
}

.purple-right-arrow {
    @include purple-right-arrow();
}

@mixin grey-right-arrow() {
    display: inline-block;
    &:after {
        content: '';
        @include icon-sprite-grey-right-arrow();
        display: inline-block;
        margin-left: 10px;
    }
}

.grey-right-arrow {
    @include grey-right-arrow();
}

@mixin sr-only() {
     position: absolute;
     width: 1px;
     height: 1px;
     padding: 0;
     margin: -1px;
     overflow: hidden;
     clip: rect(0,0,0,0);
     border: 0;
 }