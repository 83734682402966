.application-container {
  background: #F9F9F9;
  padding: 40px 0 30px 0;

  @include bp(sm) {
    padding: 80px 0 70px 0;
  }
}


.application-form {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 4px 0 rgba(208,208,208,0.50);
  margin: 0 auto;
  max-width: 990px;

  @at-root #{&}__header {
    padding: 20px;
    @include bp(sm) {
      padding: 65px 170px 38px 130px;
    }
  }


  @at-root #{&}__nav {
    background: #F7F5FA;
    padding: 20px;
    @include bp(sm) {
      padding: 20px 10px 20px 60px;
    }
  }

  @at-root #{&}__body {
    padding: 20px;
    @include bp(sm) {
      padding: 54px 170px 69px 130px;
    }
  }

  @at-root #{&}__footer {
    background: #F7F5FA;
    padding: 20px;
    @include bp(sm) {
      padding: 60px 75px 55px 75px;
    }

    p {
      color: #8D7CA2;
      font-size: 18px;
      margin-bottom: 12px;
    }

    a {
      @include font-gibson(600);
    }

    ul {
      @include list-unstyled();
      margin: 0;
      li {
        color: #70309F;
        font-size: 16px;
        @include bp(sm) {
          display: inline-block;
          font-size: 23px;
          margin-right: 1em;
        }
      }
    }

  }


  fieldset {
    margin-bottom: 37px;
  }

  legend {
    border-bottom: 1px solid  #E6E1F0;
    color: $brand-purple-muted;
    @include font-gibson(600);

    @include bp(sm) {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 29px;
    }
  }

}

.application-breadcrumb {
  @include list-unstyled();
  display: table;
  margin: 0;
  width: 100%;

  @at-root #{&}__item {
    color: $brand-purple-light;
    display: block;
    font-size: 18px;
    line-height: 18px;
    margin: 0 20px 10px 0;

    @include bp(sm) {
      display: table-cell;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      @include icon-sprite-unchecked-radio();
      display: inline-block;
      float: left;
      margin-right: 4px;
    }

    &--current {
      color: #66329A;
      @include font-gibson(600);
    }


    &--checked {
      &:before {
        content: '';
        @include icon-sprite-checked-radio();
        display: inline-block;
      }
    }

  }

}


.combodate {

  .form-control {
    display: inline-block;
    margin-right: .5em;
    width: auto;
  }
}