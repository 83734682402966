.promo {
  height: auto;

  @include bp(md) {
    position: relative;
  }

  @at-root #{&}__image  {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 247px;

    @include bp(sm) {
      height: 420px;
    }

    @include bp(md) {
      height: 655px;
    }
  }


  @at-root #{&}__panel {
    background: #fff;
    padding: 30px 0 40px 0;

    @include bp(sm) {
      padding: 35px 0 60px 0;
    }

    @include bp(md) {
      padding: 60px 50px;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      width: 470px;

    }

  }



  @at-root #{&}__copy {

    @at-root #{&}--p {
      color: #858585;
      font-size: 17px;
      line-height: 23px;
      margin-bottom: 24px;

      @include bp(sm) {
        font-size: 18px;
        line-height: 25px;
        max-width: 470px;
      }

    }

    @at-root #{&}--title {
      color: #5E4A77;
      @include font-gibson(600);
      font-size: 21px;
      line-height: 27px;
      margin-bottom: 13px;

      @include bp(md) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    @at-root #{&}--more {
      color: #5E2984;
      font-size: 15px;
      line-height: 18px;
      text-transform: uppercase;
    }



  }

}
