.support-search {
  margin-bottom: 20px;
  @include bp(sm) {
    margin-bottom: 52px;
  }
}

.support-container {
  @include clearfix();
}

.support-topic {

  margin-bottom: 20px;

  @include bp(sm) {
    margin-bottom: 52px;
  }

  @include bp(md) {
    float: left;
    margin-right: 5%;
    width: 45%;
  }

  @at-root #{&}__category {
    border-bottom: 1px solid #CCCCCC;
    color: $brand-purple-muted;
    @include font-gibson(600);
    line-height: 30px;
    margin-bottom: 20px;

    @include bp(sm) {
      font-size: 25px;
      margin-bottom: 38px;
    }
  }

}



.support-questions {

  @include list-unstyled();

  li {
    @include list-unstyled();
    margin-bottom: 15px;

    @include bp(sm) {
      margin-bottom: 23px;
    }
  }

  a {
    text-decoration: underline;
  }

}
