// weight 600 for semibold
@mixin font-gibson($weight:400, $style:normal) {
    font-family: $font-family-gibson;
    font-style: $style;
    font-weight: $weight;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @include font-gibson(600);
    color: $brand-purple-muted;
}


h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: 0;
    margin-bottom: ($line-height-computed / 2);
}

h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: 0;
    margin-bottom: ($line-height-computed / 2);
}

h1, .h1 {
    font-size: 29px;
    line-height: 30px;
    margin-bottom: 12px;

    @include bp(sm) {
        font-size: 33px;
        line-height: 51px;
        margin-bottom: 27px;
    }

    @include bp(md) {
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 30px;
    }
}

h2, .h2 {
    font-size: 25px;
    line-height: 30px;
}

h3, .h3 {
    font-size: 21px;
    line-height: 30px;
}

h4, .h4 {
    font-size: $font-size-h4;

    @include bp(md) {

        font-size: 21px;
        line-height: 30px;
    }
}

h5, .h5 { font-size: $font-size-h5; }

h6, .h6 { font-size: $font-size-h6; }

.hr {

    margin: 0;

    &--large {
        margin: 30px 0 20px 0;
        @include bp(sm) {
            margin: 40px 0 70px 0;
        }
    }

    &--small {
        margin: 12px 0;
    }
}

p, td, li, dt, dd {
    @include font-gibson();
    font-size: 18px;
    line-height: 25px;

    @include bp(sm) {
        font-size: 21px;
        line-height: 29px;
    }

    @include bp(md) {
        font-size: 21px;
        line-height: 29px;
        small {
            font-size: 18px;
        }
    }
}

p {
    margin-bottom: 15px;

    @include bp(sm) {
        margin-bottom: 30px;
    }

    @include bp(md) {
        margin-bottom: 32px;
    }
}

a {
    color: $brand-purple;
    @include font-gibson(600);
    text-decoration: none;

    &:hover {
        color: $brand-purple;
        text-decoration: underline;
    }

    &:focus {
        color: #B2A2C6;
        text-decoration: none;
    }

}


.link {

    &--underline {
        border-bottom: 1px solid $brand-purple;
        color: $brand-purple;
        @include font-gibson(600);
        text-decoration: none;
        &:hover {
            border-bottom: 1px solid #B2A2C6;
            color: $brand-purple;
            text-decoration: none;
        }
        &:focus {
            border-bottom: 1px solid #B2A2C6;
            color: #B2A2C6;
            text-decoration: none;
        }
    }

    &--arrow-right {
        @include purple-right-arrow();
        line-height: normal;
        &:hover {
            &:after {
                @include animation(fadeInLeft .2s ease-in-out forwards);
            }
        }
    }

    &--chevron-right {
        @include grey-right-arrow();
        line-height: normal;

        &:hover {
            &:after {
                @include animation(fadeInLeft .2s ease-in-out forwards);
            }
        }
    }

}

a.more {
    @include font-gibson(600);
    color: $brand-purple;
    font-size: 15px;
    line-height: 26px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: $brand-purple-light;
        text-decoration: none;
    }
}



a.underline {
    position: relative;
    color: $text-color;
    text-decoration: none;

    &:before {
        background-color: $brand-purple-light;
        content: "";
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 0;
        visibility: hidden;
        width: 100%;
        @include transform(scaleX(0));
        @include transition(all .2s ease-in-out 0s);
    }

    &:hover {
        text-decoration: none;
        &:before {
            visibility: visible;
            @include transform(scaleX(1));
        }
    }

}

a.fade {
    color: #fff;
    text-decoration: none;

    span {
        border-bottom: 1px solid $brand-purple-light;
        @include transition(all .2s ease-in-out);
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
        span {
            color: $brand-purple-light;
        }
    }

}


.btn {
    background: $brand-purple;
    border: 2px solid #fff;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    @include font-gibson(600);
    font-size: 16px;
    line-height: normal;
    padding: 12px 20px 12px 20px;
    margin-bottom: 0;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    @include user-select(none);

    &:hover {
        background: #ffffff;
        color: $brand-purple;
        text-decoration: none;
    }

    &--small {
        font-size: 14px;
        padding: 10px 15px 10px 15px;
    }

    &--large {
        font-size: 14px;
        padding: 10px 20px 10px 20px;

        @include bp(sm) {
            font-size: 16px;
            padding: 13px 25px 12px 25px;
        }
    }

    &--rounded {
        border-radius: $border-radius-small;
    }

    &--arrow::after {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        content: '';
        width: 5px;
        height: 9px;
        margin-left: 15px;
        background: url(/img/arrow-white-right.svg) no-repeat center center;
        background-size: 5px 9px;
    }
    &--primary {
        background: #28BD6F;
        border: 2px solid #28BD6F;
        color: #fff;

        &:hover {
            background: #2ED57D;
            border: 2px solid #2ED57D;
            color: #ffffff;
        }
    }
    &--pink {
        background: #E52D90;
        border: 2px solid #E52D90;
        color: #fff;
        border-radius: 5px;
        font-size: 13px;
        line-height: 12px;
        width: 100%;

        &:hover {
            background: #e52d8fd3;
            border: 2px solid #e52d8fd3;
            color: #ffffff;
        }
    }
    &--pink-inverted {
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        color: #E52D90;
        border-radius: 5px;
        font-size: 13px;
        line-height: 12px;
        width: 100%;

        &:hover {
            background: #fafafa;
            border: 2px solid #fafafa;
            color: #E52D90;
        }
    }


    &--secondary {
        background: #ffffff;
        border: 2px solid $brand-purple-light;
        color: $brand-purple-light;

        &:hover {
            background: $brand-purple-light;
            color: #ffffff;
        }

        &.active {
            border: 2px solid $brand-purple;
            color: $brand-purple;
            &:hover {
                background: $brand-purple;
                color: #ffffff;
            }
        }
    }

    &--danger {
        background: $brand-danger;
        border: 2px solid $brand-danger;
        color: #ffffff;

        &:hover {
            background: $brand-danger;
            color: #ffffff;
        }

        &:before {
            content: '';
            @include icon-sprite-lock();
            display: inline-block;
            float: left;
            margin: 0 10px 0 0;
        }
    }

}


.well {
    background: #F7F5FA;
    padding: 33px 20px;
}
