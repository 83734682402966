.news-archive {

  @at-root #{&}__title {
    color: $brand-purple-muted;
    @include font-gibson(600);
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 19px;

    @include bp(sm) {
      font-size: 34px;
      line-height: 30px;
      margin-bottom: 35px;
    }

    @include bp(md) {
      font-size: 32px;
      line-height: 30px;
      margin-bottom: 32px;
    }

  }

}


.news-archive-item {

  @include clearfix();

  @include bp(sm) {
    margin-bottom: 40px;
  }

  @include bp(md) {
    max-width: 90%;
  }

  a {
    display: block;
  }

  @at-root #{&}__body {
    width: 100%;

    @include bp(sm) {
      float: left;
      width: 60%;
    }
  }


  img {
    @include img-responsive();
    margin-bottom: 12px;

    @include bp(sm) {
      float: left;
      margin-right: 5%;
      width: 35%;
    }

  }


  &--title {
    color: $brand-purple-muted;
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 4px;

    @include bp(sm) {
      font-size: 25px;
      line-height: 30px;
    }

  }


  &--meta {
    color: #8D7CA2;
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 4px;

    @include bp(md) {
      margin-bottom: 19px;
    }
  }

  &--excerpt {
    font-size: 17px;
    color: #858585;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 21px;

    @include bp(sm) {
      font-size: 18px;
      line-height: 25px;
    }

  }

}


.news-item {

  margin-bottom: 48px;

  @at-root #{&}__header {
    margin-bottom: 35px;
    position: relative;
  }

  @at-root #{&}__featured-image {
    @include img-responsive();
  }

  @at-root #{&}__published {
    background-color: #F4EEFA;
    color: #5E4A77;
    @include font-gibson(600);
    font-size: 16px;
    line-height: 26px;
    padding: 12px 16px 8px 16px;
    position: absolute;
    left: 20px;
    bottom: -20px;
  }

  @at-root #{&}__title--small {

    @include bp(sm) {
      font-size: 32px;
      line-height: 30px;
    }
  }

  @at-root #{&}__category {
    color: #8D7CA2;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  @at-root #{&}__body {

  }

}
