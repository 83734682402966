
// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
.container {
    @media (min-width: $screen-xl-min) {
        width: $container-xl;
    }
}

@mixin make-grid-xlcolumns($i: 1, $list: ".col-xl-#{$i}") {

    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xl-#{$i}";
    }

    #{$list} {
        position: relative;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left:  ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }

}

@include make-grid-xlcolumns();
@media (min-width: $screen-xl-min) {
    @include make-grid(xl);
}

// Generate the large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left:  ($gutter / 2);
    padding-right: ($gutter / 2);

    @media (min-width: $screen-xl-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}

@mixin make-xl-column-offset($columns) {
    @media (min-width: $screen-xl-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}

@mixin make-xl-column-push($columns) {
    @media (min-width: $screen-xl-min) {
        left: percentage(($columns / $grid-columns));
    }
}

@mixin make-xl-column-pull($columns) {
    @media (min-width: $screen-xl-min) {
        right: percentage(($columns / $grid-columns));
    }
}
