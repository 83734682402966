.login {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 4px 0 rgba(208,208,208,0.50);
  margin: 0 auto 46px auto;
  max-width: 650px;
  padding: 30px;

  @include bp(sm) {
    padding: 55px 120px 90px 120px;
  }

  @at-root #{&}__form {
    max-width: 330px;
    margin: 0 auto 30px auto;
  }

  p {
    font-size: 18px;
    a {
      font-size: 15px;
      text-decoration: underline;
    }
  }

}


