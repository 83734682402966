
  $sprite-accordion-chevron-width: 0.875em;
  $sprite-accordion-chevron-height: 0.5em;
  $sprite-accordion-chevron-x: -0.125em;
  $sprite-accordion-chevron-y: -0.125em;

  $sprite-checked-radio-width: 1.1875em;
  $sprite-checked-radio-height: 1.1875em;
  $sprite-checked-radio-x: -0.125em;
  $sprite-checked-radio-y: -0.875em;

  $sprite-facebook-follow-width: 2.4375em;
  $sprite-facebook-follow-height: 2.4375em;
  $sprite-facebook-follow-x: -0.125em;
  $sprite-facebook-follow-y: -2.3125em;

  $sprite-grey-right-arrow-width: 0.3125em;
  $sprite-grey-right-arrow-height: 0.5625em;
  $sprite-grey-right-arrow-x: -0.125em;
  $sprite-grey-right-arrow-y: -5em;

  $sprite-grey-search-width: 1em;
  $sprite-grey-search-height: 1em;
  $sprite-grey-search-x: -0.125em;
  $sprite-grey-search-y: -5.8125em;

  $sprite-grey-user-width: 1.625em;
  $sprite-grey-user-height: 1.625em;
  $sprite-grey-user-x: -0.125em;
  $sprite-grey-user-y: -7.0625em;

  $sprite-lock-width: 0.8125em;
  $sprite-lock-height: 1em;
  $sprite-lock-x: -0.125em;
  $sprite-lock-y: -8.9375em;

  $sprite-pdf-hover-icon-width: 2.5625em;
  $sprite-pdf-hover-icon-height: 3.3125em;
  $sprite-pdf-hover-icon-x: -0.125em;
  $sprite-pdf-hover-icon-y: -10.1875em;

  $sprite-pdf-icon-width: 2.5625em;
  $sprite-pdf-icon-height: 3.3125em;
  $sprite-pdf-icon-x: -0.125em;
  $sprite-pdf-icon-y: -13.75em;

  $sprite-purple-chevron-width: 0.625em;
  $sprite-purple-chevron-height: 0.9375em;
  $sprite-purple-chevron-x: -0.125em;
  $sprite-purple-chevron-y: -17.3125em;

  $sprite-purple-right-arrow-width: 0.5em;
  $sprite-purple-right-arrow-height: 0.6875em;
  $sprite-purple-right-arrow-x: -0.125em;
  $sprite-purple-right-arrow-y: -18.5em;

  $sprite-purple-search-width: 1.0625em;
  $sprite-purple-search-height: 1.0625em;
  $sprite-purple-search-x: -0.125em;
  $sprite-purple-search-y: -19.4375em;

  $sprite-resource-hover-icon-width: 2.5625em;
  $sprite-resource-hover-icon-height: 3.3125em;
  $sprite-resource-hover-icon-x: -0.125em;
  $sprite-resource-hover-icon-y: -20.75em;

  $sprite-resource-icon-width: 2.5625em;
  $sprite-resource-icon-height: 3.3125em;
  $sprite-resource-icon-x: -0.125em;
  $sprite-resource-icon-y: -24.3125em;

  $sprite-select-chevron-width: 0.5625em;
  $sprite-select-chevron-height: 0.375em;
  $sprite-select-chevron-x: -0.125em;
  $sprite-select-chevron-y: -27.875em;

  $sprite-small-toggle-menu-width: 1.1875em;
  $sprite-small-toggle-menu-height: 1.0625em;
  $sprite-small-toggle-menu-x: -0.125em;
  $sprite-small-toggle-menu-y: -28.5em;

  $sprite-tick-bullet-width: 0.875em;
  $sprite-tick-bullet-height: 0.6875em;
  $sprite-tick-bullet-x: -0.125em;
  $sprite-tick-bullet-y: -29.8125em;

  $sprite-toggle-menu-width: 1.1875em;
  $sprite-toggle-menu-height: 1.0625em;
  $sprite-toggle-menu-x: -0.125em;
  $sprite-toggle-menu-y: -30.75em;

  $sprite-twitter-follow-width: 2.4375em;
  $sprite-twitter-follow-height: 2.4375em;
  $sprite-twitter-follow-x: -0.125em;
  $sprite-twitter-follow-y: -32.0625em;

  $sprite-unchecked-radio-width: 1.1875em;
  $sprite-unchecked-radio-height: 1.1875em;
  $sprite-unchecked-radio-x: -0.125em;
  $sprite-unchecked-radio-y: -34.75em;





.icon {
  font-size: $font-size-base;
  &:before {
    content:' ';
    vertical-align: middle;
    display: inline-block;
    background-image: url("/build/svg/sprite.svg");
    background-repeat: no-repeat;
    background-size: 2.8125em 36.0625em;

    .no-svg & {
      background-image: url("/build/svg/sprite.png") !important;
    }
  }
}

@mixin spritesheet-icon() {
  background-image: url("/build/svg/sprite.svg");
  background-repeat: no-repeat;
  background-size: 2.8125em 36.0625em;
  font-size: 16px;
  .no-svg & {
    background-image: url("/build/svg/sprite.png") !important;
  }
}



  .icon.sprite-accordion-chevron {
    &:before {
      background-position: $sprite-accordion-chevron-x $sprite-accordion-chevron-y;
      width: $sprite-accordion-chevron-width;
      height: $sprite-accordion-chevron-height;
    }
  }

  @mixin icon-sprite-accordion-chevron(){
    @include spritesheet-icon();
    background-position: $sprite-accordion-chevron-x $sprite-accordion-chevron-y;
    width: $sprite-accordion-chevron-width;
    height: $sprite-accordion-chevron-height;
  }




  .icon.sprite-checked-radio {
    &:before {
      background-position: $sprite-checked-radio-x $sprite-checked-radio-y;
      width: $sprite-checked-radio-width;
      height: $sprite-checked-radio-height;
    }
  }

  @mixin icon-sprite-checked-radio(){
    @include spritesheet-icon();
    background-position: $sprite-checked-radio-x $sprite-checked-radio-y;
    width: $sprite-checked-radio-width;
    height: $sprite-checked-radio-height;
  }




  .icon.sprite-facebook-follow {
    &:before {
      background-position: $sprite-facebook-follow-x $sprite-facebook-follow-y;
      width: $sprite-facebook-follow-width;
      height: $sprite-facebook-follow-height;
    }
  }

  @mixin icon-sprite-facebook-follow(){
    @include spritesheet-icon();
    background-position: $sprite-facebook-follow-x $sprite-facebook-follow-y;
    width: $sprite-facebook-follow-width;
    height: $sprite-facebook-follow-height;
  }




  .icon.sprite-grey-right-arrow {
    &:before {
      background-position: $sprite-grey-right-arrow-x $sprite-grey-right-arrow-y;
      width: $sprite-grey-right-arrow-width;
      height: $sprite-grey-right-arrow-height;
    }
  }

  @mixin icon-sprite-grey-right-arrow(){
    @include spritesheet-icon();
    background-position: $sprite-grey-right-arrow-x $sprite-grey-right-arrow-y;
    width: $sprite-grey-right-arrow-width;
    height: $sprite-grey-right-arrow-height;
  }




  .icon.sprite-grey-search {
    &:before {
      background-position: $sprite-grey-search-x $sprite-grey-search-y;
      width: $sprite-grey-search-width;
      height: $sprite-grey-search-height;
    }
  }

  @mixin icon-sprite-grey-search(){
    @include spritesheet-icon();
    background-position: $sprite-grey-search-x $sprite-grey-search-y;
    width: $sprite-grey-search-width;
    height: $sprite-grey-search-height;
  }




  .icon.sprite-grey-user {
    &:before {
      background-position: $sprite-grey-user-x $sprite-grey-user-y;
      width: $sprite-grey-user-width;
      height: $sprite-grey-user-height;
    }
  }

  @mixin icon-sprite-grey-user(){
    @include spritesheet-icon();
    background-position: $sprite-grey-user-x $sprite-grey-user-y;
    width: $sprite-grey-user-width;
    height: $sprite-grey-user-height;
  }




  .icon.sprite-lock {
    &:before {
      background-position: $sprite-lock-x $sprite-lock-y;
      width: $sprite-lock-width;
      height: $sprite-lock-height;
    }
  }

  @mixin icon-sprite-lock(){
    @include spritesheet-icon();
    background-position: $sprite-lock-x $sprite-lock-y;
    width: $sprite-lock-width;
    height: $sprite-lock-height;
  }




  .icon.sprite-pdf-hover-icon {
    &:before {
      background-position: $sprite-pdf-hover-icon-x $sprite-pdf-hover-icon-y;
      width: $sprite-pdf-hover-icon-width;
      height: $sprite-pdf-hover-icon-height;
    }
  }

  @mixin icon-sprite-pdf-hover-icon(){
    @include spritesheet-icon();
    background-position: $sprite-pdf-hover-icon-x $sprite-pdf-hover-icon-y;
    width: $sprite-pdf-hover-icon-width;
    height: $sprite-pdf-hover-icon-height;
  }




  .icon.sprite-pdf-icon {
    &:before {
      background-position: $sprite-pdf-icon-x $sprite-pdf-icon-y;
      width: $sprite-pdf-icon-width;
      height: $sprite-pdf-icon-height;
    }
  }

  @mixin icon-sprite-pdf-icon(){
    @include spritesheet-icon();
    background-position: $sprite-pdf-icon-x $sprite-pdf-icon-y;
    width: $sprite-pdf-icon-width;
    height: $sprite-pdf-icon-height;
  }




  .icon.sprite-purple-chevron {
    &:before {
      background-position: $sprite-purple-chevron-x $sprite-purple-chevron-y;
      width: $sprite-purple-chevron-width;
      height: $sprite-purple-chevron-height;
    }
  }

  @mixin icon-sprite-purple-chevron(){
    @include spritesheet-icon();
    background-position: $sprite-purple-chevron-x $sprite-purple-chevron-y;
    width: $sprite-purple-chevron-width;
    height: $sprite-purple-chevron-height;
  }




  .icon.sprite-purple-right-arrow {
    &:before {
      background-position: $sprite-purple-right-arrow-x $sprite-purple-right-arrow-y;
      width: $sprite-purple-right-arrow-width;
      height: $sprite-purple-right-arrow-height;
    }
  }

  @mixin icon-sprite-purple-right-arrow(){
    @include spritesheet-icon();
    background-position: $sprite-purple-right-arrow-x $sprite-purple-right-arrow-y;
    width: $sprite-purple-right-arrow-width;
    height: $sprite-purple-right-arrow-height;
  }




  .icon.sprite-purple-search {
    &:before {
      background-position: $sprite-purple-search-x $sprite-purple-search-y;
      width: $sprite-purple-search-width;
      height: $sprite-purple-search-height;
    }
  }

  @mixin icon-sprite-purple-search(){
    @include spritesheet-icon();
    background-position: $sprite-purple-search-x $sprite-purple-search-y;
    width: $sprite-purple-search-width;
    height: $sprite-purple-search-height;
  }




  .icon.sprite-resource-hover-icon {
    &:before {
      background-position: $sprite-resource-hover-icon-x $sprite-resource-hover-icon-y;
      width: $sprite-resource-hover-icon-width;
      height: $sprite-resource-hover-icon-height;
    }
  }

  @mixin icon-sprite-resource-hover-icon(){
    @include spritesheet-icon();
    background-position: $sprite-resource-hover-icon-x $sprite-resource-hover-icon-y;
    width: $sprite-resource-hover-icon-width;
    height: $sprite-resource-hover-icon-height;
  }




  .icon.sprite-resource-icon {
    &:before {
      background-position: $sprite-resource-icon-x $sprite-resource-icon-y;
      width: $sprite-resource-icon-width;
      height: $sprite-resource-icon-height;
    }
  }

  @mixin icon-sprite-resource-icon(){
    @include spritesheet-icon();
    background-position: $sprite-resource-icon-x $sprite-resource-icon-y;
    width: $sprite-resource-icon-width;
    height: $sprite-resource-icon-height;
  }




  .icon.sprite-select-chevron {
    &:before {
      background-position: $sprite-select-chevron-x $sprite-select-chevron-y;
      width: $sprite-select-chevron-width;
      height: $sprite-select-chevron-height;
    }
  }

  @mixin icon-sprite-select-chevron(){
    @include spritesheet-icon();
    background-position: $sprite-select-chevron-x $sprite-select-chevron-y;
    width: $sprite-select-chevron-width;
    height: $sprite-select-chevron-height;
  }




  .icon.sprite-small-toggle-menu {
    &:before {
      background-position: $sprite-small-toggle-menu-x $sprite-small-toggle-menu-y;
      width: $sprite-small-toggle-menu-width;
      height: $sprite-small-toggle-menu-height;
    }
  }

  @mixin icon-sprite-small-toggle-menu(){
    @include spritesheet-icon();
    background-position: $sprite-small-toggle-menu-x $sprite-small-toggle-menu-y;
    width: $sprite-small-toggle-menu-width;
    height: $sprite-small-toggle-menu-height;
  }




  .icon.sprite-tick-bullet {
    &:before {
      background-position: $sprite-tick-bullet-x $sprite-tick-bullet-y;
      width: $sprite-tick-bullet-width;
      height: $sprite-tick-bullet-height;
    }
  }

  @mixin icon-sprite-tick-bullet(){
    @include spritesheet-icon();
    background-position: $sprite-tick-bullet-x $sprite-tick-bullet-y;
    width: $sprite-tick-bullet-width;
    height: $sprite-tick-bullet-height;
  }




  .icon.sprite-toggle-menu {
    &:before {
      background-position: $sprite-toggle-menu-x $sprite-toggle-menu-y;
      width: $sprite-toggle-menu-width;
      height: $sprite-toggle-menu-height;
    }
  }

  @mixin icon-sprite-toggle-menu(){
    @include spritesheet-icon();
    background-position: $sprite-toggle-menu-x $sprite-toggle-menu-y;
    width: $sprite-toggle-menu-width;
    height: $sprite-toggle-menu-height;
  }




  .icon.sprite-twitter-follow {
    &:before {
      background-position: $sprite-twitter-follow-x $sprite-twitter-follow-y;
      width: $sprite-twitter-follow-width;
      height: $sprite-twitter-follow-height;
    }
  }

  @mixin icon-sprite-twitter-follow(){
    @include spritesheet-icon();
    background-position: $sprite-twitter-follow-x $sprite-twitter-follow-y;
    width: $sprite-twitter-follow-width;
    height: $sprite-twitter-follow-height;
  }




  .icon.sprite-unchecked-radio {
    &:before {
      background-position: $sprite-unchecked-radio-x $sprite-unchecked-radio-y;
      width: $sprite-unchecked-radio-width;
      height: $sprite-unchecked-radio-height;
    }
  }

  @mixin icon-sprite-unchecked-radio(){
    @include spritesheet-icon();
    background-position: $sprite-unchecked-radio-x $sprite-unchecked-radio-y;
    width: $sprite-unchecked-radio-width;
    height: $sprite-unchecked-radio-height;
  }



