.panel {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 4px 0 rgba(208,208,208,0.50);
  margin: 0 auto 46px auto;
  width: auto;

  &--contact {
    max-width: 840px;

    & > .panel__body {
      display: none;
    }
  }


  @at-root #{&}__block {
    width: 100%;
  }

  @at-root #{&}__header {
    @include font-gibson(600);
    font-size: 21px;
    line-height: 30px;
    padding: 57px 20px;

    @include bp(lg) {
      color: #07954B;
      font-size: 23px;
      line-height: 30px;
      padding: 36px 20px;
    }

    @include bp(xl) {
      padding: 36px 40px;
    }

    &--green {
      background: rgb(237,250,243);
      color: #07954B;

      & > span, p {
        color: #07954B;
      }

    }
  }

  @at-root #{&}__body {
    color: #828282;
    font-size: 16px;
    line-height: 19px;
    padding: 30px 20px;

    @include bp(lg) {
      font-size: 18px;
    }

    @include bp(xl) {
      padding: 20px 35px;
    }

    &--no-header {
      @include bp(sm) {
        padding-top: 50px;
      }
    }

    &--no-footer {
      @include bp(sm) {
        padding-bottom: 50px;
      }
    }

    p {
      color: #828282;
      font-size: 16px;
      line-height: 19px;

      @include bp(lg) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    & > p:last-child {
      margin-bottom: 0;
    }


  }

  @at-root #{&}__footer {
    @include font-gibson(600);
    font-size: 21px;
    line-height: 30px;
    padding: 30px 20px;
  }

}



