.main-header {
  @include clearfix();
  background: $brand-purple;
  display: table;
  width: 100%;

  @at-root #{&}__toolbar {
    @include clearfix();
    background: #3C275F;
    display: table-footer-group;

    @include bp(sm) {
      background: $brand-purple-dark;
      display: table-header-group;
      height: 40px;
    }

  }


  @at-root #{&}__strapline {
    color: $brand-purple-lighter;
    display: none;
    @include font-gibson(400, italic);
    font-size: 14px;
    line-height: 40px;
    margin: 0;

    @include bp(sm) {
      display: inline-block;
      font-size: 15px;
    }
  }


  @at-root #{&}__masthead {
    background: #553A81;
    display: table-header-group;
    height: 80px;
    line-height: 80px;
    text-align: left;

    @include bp(sm) {
      background: $brand-purple;
      display: table-footer-group;
      height: 105px;
      line-height: 105px;
      text-align: right;
    }

  }


  .apply-online {
    display: inline-block;
    float: right;
  }


  @at-root #{&}__logo {
    display: block;
    float: left;

    img {
      @include img-responsive();
      margin-top: 14px;
      max-height: 50px;

      @include bp(sm) {
        margin-top: 14px;
        max-height: inherit;
      }
    }
  }


}


.shoulder-nav {
  @include user-select(none);
  overflow: hidden;
  max-width: 100%;
  width: 100%;

  @include bp(sm) {
    float: right;
    position: relative;
    width: auto;
  }

  @include bp(md) {
    padding-left: 0;
  }

  ul {
    display: table;
    @include list-unstyled();
    margin: 0;
    padding: 0;
    width: 100%;

    @include bp(sm) {
      width: auto;
    }

  }

  li {
    border-right: 1px solid #514972;
    color: $brand-purple-lighter;
    display: table-cell;
    height: 40px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    width: 33%;

    &:last-child {
      border-right: none;
    }

    @include bp(sm) {
      border: none;
      padding: 0 17px 0 17px;
      width: auto;
    }

    &.account-login {
      @include bp(sm) {
        border-right: 1px solid #5a3875;
        border-left: 1px solid #5a3875;
      }
    }

    &.search-menu {
      @include font-gibson(600);
      font-size: 13px;
      text-transform: uppercase;

      @include bp(sm) {
        border-right: 1px solid #5a3875;
        padding: 0 14px 0 14px;
      }

      @include bp(md) {
        padding: 0 6px 0 14px;
      }
    }

    a {
      color: $brand-purple-lighter;
      display: block;
      @include font-gibson(600);
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;

      &:hover {
        color: #fff;
        text-decoration: none;
      }

    }

  }

}


.toggle-search {
  color: $brand-purple-lighter;
  cursor: pointer;
  display: inline-block;
  @include font-gibson(600);
  font-size: 13px;
  line-height: normal;
  margin-left: 25px;
  position: relative;
  text-transform: uppercase;
  top: 2px;
  @include user-select(none);

  @include bp(md) {
    top: -2px;
  }

  &:before {
    @include icon-sprite-grey-search();
    content: '';
    display: block;
    position: absolute;
    left: -25px;
    top: -1px;
  }


  @at-root #{&}__label {
    @include bp(md) {
      @include sr-only();
    }
  }
}


.account-login {

  @include bp(sm) {
    border-right: 1px solid #5a3875;
    border-left: 1px solid #5a3875;
  }

  @at-root #{&}__label {
    position: relative;
    margin-left: 35px;

    &:before {
      @include icon-sprite-grey-user();
      content: '';
      display: block;
      position: absolute;
      left: -35px;
      top: -6px;
    }

    @at-root #{&}--xs {
      display: inline-block;
      @include bp(md) {
        display: none;
      }
    }

    @at-root #{&}--md {
      display: none;
      @include bp(md) {
        display: inline-block;
      }
    }

  }


}


.main-nav {
  @include user-select(none);
  background: #3C275F;
  border-top: 1px solid #4D506E;
  display: none;
  position: absolute;
  top: 120px;
  right: 0;
  left: 0;
  z-index: 10;

  @include bp(sm) {
    background: $brand-purple-dark;
    border-top: none;
    top: 145px;
  }

  @include bp(md) {
    background: transparent;
    display: inline-block;
    text-align: right;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }

  @include bp(lg) {

  }

  ul {
    @include list-unstyled();
    margin: 0 25px;
    padding: 0;

    @include bp(sm) {
      margin: 0 40px;
    }

    @include bp(md) {
      margin: 0;
    }
  }

  li {
    border-bottom: 1px solid #4D506E;
    height: 55px;
    line-height: 55px;
    text-align: left;

    @include bp(sm) {
      border-bottom: 1px solid #64417D;
    }

    @include bp(md) {
      border: none;
      display: inline-block;
      line-height: 105px;
      margin: 0 30px 0 0;
    }

    a {
      color: #ffffff;
      display: block;
      @include font-gibson(600);
      font-size: 21px;

      @include bp(md) {
        display: inline-block;
        font-size: 23px;
      }

    }

  }
}


.toggle-menu {
  color: $brand-purple-lighter;
  cursor: pointer;
  display: inline-block;
  @include font-gibson(600);
  font-size: 13px;
  line-height: normal;
  text-transform: uppercase;
  top: 3px;
  @include user-select(none);

  @include bp(sm) {
    color: #ffffff;
    font-size: 16px;
    margin-right: 30px;
  }

  &:before {
    @include icon-sprite-small-toggle-menu();
    content: '';
    display: inline-block;
    float: left;
    margin-right: 11px;
    @include bp(sm) {
      @include icon-sprite-toggle-menu();
    }
  }

}


.toggle-main-nav-checkbox {
  display: none;

  &:checked {
    & + .main-nav {
      display: block;
      @include bp(md) {
        display: inline-block;
      }
    }
  }
}




.search-box {
  background: #fff;
  border-bottom: 1px solid $brand-purple-lighter;
  height: 56px;
  line-height: normal;
  padding: 0 0 0 12px;

  @include bp(sm) {
    background: $brand-purple-dark;
    border-bottom: none;
    height: 40px;
  }


  @at-root #{&}__query {
    background: #fff;
    border: none;
    color: $gray;
    @include font-gibson(400);
    display: inline-block;
    float: left;
    font-size: 18px;
    height: 54px;
    line-height: normal;
    margin: 0;
    outline: none;
    padding: 0;
    width: calc(100% - 50px);

    @include placeholder {
      color: #CEC4DC;
    }

    @include bp(sm) {
      background: $brand-purple-dark;
      color: #ffffff;
      font-size: 15px;
      height: 40px;
      width: calc(100% - 45px);

      @include placeholder {
        color: #CEC4DC;
        font-style: italic;
      }
    }

  }

  @at-root #{&}__close { // close button
    background: #fff;
    border: none;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 24px;
    line-height: 54px;
    height: 54px;
    outline: none;
    text-align: center;
    width: 50px;
    @include appearance(none);

    @include bp(sm) {
      background: $brand-purple-dark;
      font-size: 20px;
      height: 40px;
      line-height: 40px;
      margin-top: -2px;
      width: 45px;
    }

  }

}



.shoulder-nav__search {
  @include clearfix();
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 99;

  @include bp(sm) {
    background: $brand-purple-dark;
    border-bottom: none;
    border-right: 1px solid #5a3875;
    border-left: 1px solid #5a3875;
    height: 40px;
    top: 0;
  }
}

.toggle-toolbox-search-checkbox {
  display: none;

  & + .shoulder-nav__search {
    opacity: 0;
    visibility: hidden;
    @include transition(opacity .2s ease-in-out);
  }

  &:checked {
    & + .shoulder-nav__search {
      opacity: 1;
      visibility: visible;
    }
  }


}
