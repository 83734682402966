.simple-header {
  @include clearfix();
  background: $brand-purple;
  height: 80px;
  line-height: 80px;
  width: 100%;

  @include bp(sm) {
    height: 125px;
    line-height: 125px;
  }


  @at-root #{&}__logo {
    img {
      max-height: 50px;

      @include bp(sm) {
        max-height: inherit;
      }
    }
  }

  @at-root #{&}__back {
    color: #ffffff;
    @include font-gibson(600);
    font-size: 16px;

    @include bp(sm) {
      font-size: 23px;
    }

    &:hover {
      color: #ffffff;
    }

  }


}




.simple-footer {

  background: $brand-purple;
  padding: 30px 0 25px 0;

  @include bp(sm) {
    padding: 40px 0 35px 0;
  }


  @at-root #{&}__misc {
    color: #ffffff;
    font-size: 15px;
    text-align: center;

    ul {
      @include list-unstyled();


      li {
        font-size: 13px;
        line-height: 16px;

        @include bp(sm) {
          display: inline-block;
          font-size: 15px;
          margin-right: 2em;
        }


        a {
          color: #ffffff;
          @include font-gibson(400);
          &:hover {
            color: #ffffff;
            text-decoration: underline;
          }
        }


      }
    }
  }



}
