.enhance-section {

  & .featured-header__title--enhance-inner {
    // position: initial;
    background: #8E60CA;
    color: #ffffff;
    font-size: 50px;
    line-height: 60px;
    padding: 30px 20px 20px 20px;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  & .featured-header__title--enhance-inner-small {
    position: absolute !important;
    background: #8E60CA;
    color: #ffffff;
    font-size: 50px;
    line-height: 60px;
    padding: 30px 20px 20px 20px;
    bottom:-30px;
  }

  & .featured-resources {
    & h3 {
      color:#8E60CA;
    }
    & .resource-list {
      & li {
        width: 33%;
        padding: 0 50px 20px 0;
        margin: 0 0 20px 0;
        display:inline-flex;
        & .resource {
          & .resource__copy {
            & .resource__title--purple {
              color:#4F4F4F;
            }
          }
        }
      }
    }
  }

  & .featured-header__title--purple {
    display: none;
  }

	& .navigation {
		& a {
			color:#FFFFFF;
		}
	}

	& .apply-online {
		& a {
			color:#FFFFFF;
		}
	}

	& .main-header__masthead {
	    background: #8E60CA;
	}

	& .main-header__logo {
		width:26%;
    & .enhance-header-logo-wpt {
      border-right:1px solid #ffffff;
      padding-right:20px;
      margin-right:20px;
      max-width: 50%;
      margin-top:20px;
		}
		& img {
			display:inline-block;
      float: left;
      max-width: 40%;
    }
  }
  & .enhance-header-inner {
    margin-bottom:80px;
  }
	& .featured-header--enhance-inner {
		& .container {
			padding-top: 8%;

			& h1 {
				position: initial;
				max-width:100%;
				margin-bottom:30px;
			}

			& .enhance-subtitle {
				color:#ffffff;
				width:60%;
				font-size:20px;
				line-height: 30px;
				margin-bottom:20px;
        @include bp(md) {
          font-size:28px;
          line-height: 35px;
        }
			}

			& p {
				color:#ffffff;
				& a {
					color:#ffffff;
					text-decoration: underline;
				}
			}
		}
	}
	& .quick-links__title {
    color:#8E60CA;
  }
  & .link--arrow-right-enhance::after {
      content: '';
      background-image: url(/img/purple-right-arrow-enhance.svg);
      background-repeat: no-repeat;
      font-size: 16px;
      width: 0.5em;
      height: 0.6875em;
      display: inline-block;
      margin-left: 10px;
      background-size:auto;
      background-position: center;
  }
  & .link--chevron-right-enhance:after {
      content: '';
      background-image: url(/img/purple-chevron-enhance.svg);
      background-repeat: no-repeat;
      background-size: auto;
      font-size: 16px;
      background-position:center;
      width: 0.3125em;
      height: 0.5625em;
      display: inline-block;
      margin-left: 10px;
  }
	& .news-archive__title {
		color:#8E60CA;
	}
	& .promo__copy--title {
		color:#8E60CA;
	}
	& .main-footer__cta {
		background-color:#8E60CA;
		& a {
			color:#FFFFFF;
		}
	}
	& a {
		color:#8E60CA;
  }
  
  & .btn--primary {
    color:#ffffff;
  }

	& .contact-us {
		& h4 {
			color:#8E60CA;
		}
	}

	& .enhance-footer--logos {
		& img {
			width:50%;
			float:left;
		}
		& .main-footer-logo-wpt {
			border-right:1px solid #6D6E71;
			padding-right:20px;
		}
		& .main-footer-logo-enhance {
			padding-left:20px;
		}
	}

}

// Homepage

.featured-header {
  background-position: center center;
  background-size: cover;
  height: 240px;
  margin: 0 auto 32px auto;
  position: relative;

  @include bp(md) {
    height: 560px;
    margin: 0 auto 50px auto;
  }

  &--large {
    height: 400px;
    margin: 0 auto;
    @include bp(md) {
      height: 700px;
    }
  }

  &--inner {
    position: absolute;
    bottom:130px;
    background: transparent;
    padding:0 15px 0 0;
    & .enhance-cta {
      width:30%;
      display:inline-block;
      padding-left:10px;
       &--inner {
          background-color: #FFFFFF;
          padding: 20px 10px 20px 42%;
          width: 100%;
          background-image: url(/img/enhance-image.svg);
          float: left;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: contain;
          border-top-left-radius: 95px;
          border-bottom-left-radius: 95px;
        & p {
          margin:0;
          font-size: 16px;
          line-height: 24px;
          & a {
            text-decoration: none;
            color:#828282;
            font-weight: normal;
          }
          &.enhance-info {
            margin-bottom:20px;
          }
        }
        & .enhance-title {
          font-size:17px;
          line-height: 20px;
          margin-bottom:8px;
          & a {
            text-decoration: underline;
          }
        }
       }
    }
  }

  @at-root #{&}__title {
    @include font-gibson(600, normal);
    display: inline-block;
    margin: 0;
    padding: 0;

    @at-root #{&}--inside {
      text-shadow: 0 0 20px rgba(#000000, 0.5);
    }


    &--outside {
      bottom: -30px;
      position: absolute;
    }


    &--white-enhance {
      color: #ffffff;
      font-size: 25px;
      line-height: 28px;
      width:69%;
      display: inline-block;
      vertical-align: bottom;
      position:initial;
      max-width:none;

      @include bp(sm) {
        font-size: 41px;
        line-height: 48px;
      }

      @include bp(md) {
        font-size: 54px;
        line-height: 60px;
      }
    }


    &--purple {
      background: #F4EEFA;
      color: $brand-purple-muted;
      font-size: 30px;
      line-height: 43px;
      padding: 8px 18px;

      @include bp(sm) {
        font-size: 41px;
        line-height: 48px;
      }

      @include bp(md) {
        font-size: 50px;
        line-height: 60px;
        max-width: 900px;
        padding: 30px 20px 20px 20px;
      }
    }

  }

}

// Media

@media screen and (max-width:1500px) {

  .featured-header__title--white-enhance {
    width: 50%;
  }
  .featured-header--inner {
    & .enhance-cta {
      width: 49%;
    }
  }
}

@media screen and (min-width:$screen-md + 1) and (max-width:$screen-lg) {
  .featured-header__title--white-enhance {
    width: 100%;
  }
  .featured-header--inner {
    & .enhance-cta {
      width: 70%;
      padding-left: 0;
      margin-top: 20px;
    }
    & .enhance-cta--inner {
      padding-left: 25%;
    }
  }

  .enhance-section {
    & .featured-resources {
      & .resource-list {
        & li {
          width:49%;
        }
      }
    }
  }
}

@media screen and (max-width:$screen-md) {


  .support-panel {
    & h2 {
      color: white;
      position: relative;
      text-align: center;
      vertical-align: middle;
      margin: 35% auto 0 auto !important;
      font-size:35px !important;
      line-height:35px !important;
      width:100%;
    }
  }
  

  .main-header__logo {
		width:50% !important;
    & .enhance-header-logo-wpt {
      border-right:1px solid #ffffff;
      padding-right:20px;
      margin-right:20px;
      max-width: 45%;
      margin-top:20px;
		}
		& img {
			display:inline-block;
      float: left;
      max-width: 40%;
    }
	}

  .enhance-section {
    & .enhance-header-inner {
      height:240px !important;
    }
    & .featured-header--enhance-inner {
      height:600px;
      & .container {
        & .enhance-subtitle {
          width:100%;
        }
      }
    }
  }

  .featured-header__title--white-enhance {
    width: 100%;
  }

  .featured-header--inner {
    bottom:100px;
    & .enhance-cta {
      width: 100%;
      padding-left: 0;
      margin-top: 20px;
    }
    & .enhance-cta--inner {
      padding-left: 25%;
    }
  }

  .enhance-section {
    & .featured-resources {
      & .resource-list {
        & li {
          width:49%;
        }
      }
    }
  }
}

@media screen and (max-width:678px) {
  
  .featured-header--inner {
    & .enhance-cta--inner {
      background: white;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0;
      padding-left: 20px;
    }
  }

  .enhance-section {

    & .featured-header__title--enhance-inner-small {
      font-size: 30px;
      line-height: 43px;
      padding: 8px 18px;
    }
    
    & .featured-resources {
      & .resource-list {
        & li {
          width:100%;
        }
      }
    }
  }

  .support-block:first-of-type {
    margin-bottom:100px;
  }

  .block {
    display: none;
  }

  .support-panel {
    & h2 {
      color: white;
      position: relative;
      text-align: center;
      vertical-align: middle;
      margin: 35% auto 0 auto !important;
      font-size:35px !important;
      line-height:35px !important;
      width:100%;
    }
  }

  .enhance-section {
    .main-header__logo {
      width:60%;
      & img {
        max-width:50%;
      }
      & .enhance-header-logo-enhance {
        margin-top:10px;
        padding-left:10px;
      }
      & .enhance-header-logo-wpt {
        border-right: 1px solid #ffffff;
        padding-right: 10px;
        margin-right: 0px;
        padding-left:0;
      }
    }
  }
}

.support-panel {
  box-shadow: none;
  border: 1px solid transparent;
  & a:hover {
    text-decoration: none!important;
  }
  & img {
    width:90%;
    position: absolute;
  }
  & h2 {
    color: white;
    position: relative;
    text-align: center;
    vertical-align: middle;
    margin: 45% auto 0 auto;
    font-size:45px;
    line-height:45px;
    width:80%;
  }
  & p {
    color: white;
    position: relative;
    text-align: center;
    vertical-align: middle;
  }
}


.block {
  height:150px;
}

.enhance-mobile-only {
  max-width: 100%;
  margin: 0 auto;
  & .enhance-cta-mobile {
    background-color:#8E60CA;
    padding:15px;
    & .enhance-title {
      font-size:14px;
      & a {
        color:#ffffff;
      }
    }
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .items {
    display: inline-block;
    vertical-align: middle;
    p {
      margin-bottom: 10px;
    }
    &.copy {
      width:66%;
    }
    &.image {
      width:33%;
    }
  }
}

@media screen and (min-width:991px) {
  .enhance-mobile-only {
    display: none;
  }
}

@media screen and (max-width:991px) {
  .enhance-cta {
    display: none !important;
  }
}

.heroAssets {
  display: block;
  padding: 0 20px;
  position: relative;
  &--title {
    width: 100%;
    @media screen and (min-width:1200px) {
      width: 50%;
      float: left;
      max-width: 797px;
      padding-left: 0;
    }
    @media screen and (min-width:1400px) {
      width: 55%;
    }
    @media screen and (min-width:1440px) {
      width: 60%;
    }
    > h1.child-page-h1 {
      width: auto;
      display: block;
      transform: translateY(225px);
    }
    > h1.home {
      width: auto;
      display: block;
      position: relative;
      bottom: 0px!important;
    }
  }
  &--cta {
    width: 100%;
    @media screen and (min-width:1200px) {
      width: 50%;
      float: right;
    }
    @media screen and (min-width:1400px) {
      width: 45%;
    }
    @media screen and (min-width:1440px) {
      width: 40%;
    }
    > div {
      width: 100%!important;
    }
  }
}

.enhance-aside {
  &--icon {
    margin: 0 auto;
    max-width: 100px;
    img {
      display: block;
      width: 30%;
      margin-bottom: 20px;
      // margin: 0 auto;
    }
  }
  &--copy {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
}


