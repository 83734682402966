.breadcrumb {

  margin-bottom: 20px;

  @include bp(sm) {
    margin-bottom: 33px;
  }

  @include bp(md) {
    margin-bottom: 38px;
  }

  @at-root #{&}__list {
    @include list-unstyled();
  }

  @at-root #{&}__item {
    color: $brand-purple-light;
    font-size: 13px;
    display: inline-block;

    a {
      border: none;
      color: inherit;
      @include font-gibson(400);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    &:after {
      content: '\00a0\00a0\00a0|\00a0\00a0\00a0';
      display: inline-block;
    }
    &:last-child {
      &:after {
        content: '';
      }
    }

    @include bp(sm) {
      font-size: 15px;
    }

  }

}
